import { t } from "i18next";
import { ChevronRight } from "react-feather";
import { Link } from "react-router-dom";
import NoData from "../no-data/nodata";
import InfoJobApplicantsBlock from "./info-jobs-applicants";
import InfoSelfBlock from "./info-self";
import MotivatedCandidatesBlock from "./motivated-candidates";
import SmartAnalyticsInsightsBlock from "./smart-analytics-insights";
import SmartSortingBlock from "./smart-sorting";
import AgencyJobsQuestionsBlock from "./agency-jobs-questions";
import AgencySmartInsightsBlock from "./agency-smart-insights";
import AgencySmartSortingBlock from "./agency-smart-sorting";

export default function ToolboxRender(props) {
    switch (props.type) {
        case "motivated_candidates":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <MotivatedCandidatesBlock />
            </>;
        case "info_jobs_and_applicants":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <InfoJobApplicantsBlock />
            </>;
        case "info_self":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <InfoSelfBlock />
            </>;
        case "smart_analytics_insights":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <SmartAnalyticsInsightsBlock />
            </>;
        case "smart_sorting":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <SmartSortingBlock />
            </>;
        // AGENCY BLOCKS
        case "agency_jobs_questions":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <AgencyJobsQuestionsBlock />
            </>;
        case "agency_smart_insights":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <AgencySmartInsightsBlock />
            </>;
        case "agency_smart_sorting":
            return <>
                <ToolboxBreadcrumbs title={props.label} />
                <AgencySmartSortingBlock />
            </>;
        default:
            return <>
                <ToolboxBreadcrumbs title={"404"} />
                <NoData text={t("business_toolbox_content_not_found")} />
            </>;
    }
}

function ToolboxBreadcrumbs(props) {
    return <div className="d-flex flex-wrap align-items-center flex-row mb-3">
        <Link to="/wise" className="hover color-grey" style={{ textDecoration: "underline" }}>
            {t("business_main_home")}
        </Link>
        <ChevronRight className="mx-1 color-grey" size={16} />
        <span className="color-grey">{props.title}</span>
    </div>
}
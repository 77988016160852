import { useQuery } from "@tanstack/react-query";
import { AutoComplete, Button, Form } from "antd";
import Loading from "components/animations/loading";
import JobProbeQuestion from "components/forms/job/job-probe-question";
import Panel from "components/modules/panel/panel";
import useGetJobProbeOptions from "components/requests/data/useGetJobProbeOptions";
import { sendAxiosGetRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useGetJobs } from "pages/jobs/jobs";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import NoData from "../no-data/nodata";

export default function AgencyJobsQuestionsBlock(props) {
    let [searchParams, setSearchParams] = useSearchParams();
    const userStore = useUserStore();

    const [filterJobNames, setFilterJobNames] = useState(undefined);
    const [filterJob, setFilterJob] = useState(undefined);

    const allJobsKeyTranslation = t("business_dashboard_filter_select_all_jobs"); // Important to keep track for filterering purposes

    const { data, refetch: jobsRefetch, isLoading: jobsIsLoading, isRefetching: jobsIsRefetching, isError: jobsIsError } = useGetJobs({
        setData: setFilterJobNames, extraCallback: (res) => {
            if (res && res.length > 0 && searchParams.get("jobId")) {
                if (searchParams.get("jobId") && res.find(x => x.jobId === searchParams.get("jobId"))) {
                    setFilterJob(res.find(x => x.jobId === searchParams.get("jobId")));
                }
                // else {
                //     setFilterJob(res[0]);
                // }
            }
            if (res && res.length > 0) {
                const filterJobNames = res.map((item) => {
                    const multiItems = res.filter(x => x.jobName && item.jobName && x.jobName.toLowerCase() === item.jobName.toLowerCase());
                    const index = multiItems.findIndex(x => x.jobId === item.jobId);
                    const actualVal = multiItems.length > 1 ? `${item.jobName} (${index + 1})` : item.jobName;

                    return { label: actualVal, value: actualVal, id: item.jobId, ...item }
                })

                setFilterJobNames([{ label: allJobsKeyTranslation, value: allJobsKeyTranslation, isAllJobs: true }, ...filterJobNames]);
            }
        }
    });

    const { data: jobProbeOptions, isLoading: isLoadingJobProbeOptions } = useGetJobProbeOptions();

    const emptyGuid = "00000000-0000-0000-0000-000000000000";
    const { data: jobMessages, refetch: jobMessagesRefetch, isLoading: jobMessagesIsLoading, isRefetching: jobMessagesIsRefetching, isError } = useQuery({
        queryKey: ["jobProbeQuestions"],
        queryFn: () => sendAxiosGetRequest({
            route: `v5/business/select/jobs/${filterJob.isAllJobs ? emptyGuid : filterJob.jobId}/probes`,
        }),
        enabled: false
    });

    useEffect(() => {
        if (filterJob && filterJob.isAllJobs) {
            setSearchParams({ content: searchParams.get("content"), jobId: "all" });
            jobMessagesRefetch();
        }
        else if (filterJob && filterJobNames && filterJobNames.length > 0 && filterJobNames.find(x => x.jobId === filterJob.jobId)) {
            setSearchParams({ content: searchParams.get("content"), jobId: filterJob.jobId });
            jobMessagesRefetch();
        }
        else {
            if (searchParams.get("jobId")) {
                setSearchParams({ content: searchParams.get("content") });
            }
        }
    }, [filterJob])

    return <>
        <h1 className='title'>{t("business_toolbox_my_jobs_questions_agency")}{filterJob && `: ${filterJob.jobName}`}</h1>
        {
            filterJobNames && filterJobNames.length > 0 &&

            <Form
                onFinish={(values) => {
                    if (values && values.job) {
                        console.log(values)
                        if (values.job && values.job.toLowerCase() === allJobsKeyTranslation.toLowerCase()) {
                            setFilterJob({ jobName: allJobsKeyTranslation, isAllJobs: true });
                        } else {
                            var selectedOption = filterJobNames.find(x => x.value.toLowerCase() === values.job.toLowerCase());
                            if (selectedOption) {
                                setFilterJob(data.find(x => x.jobId === selectedOption.id));
                            }
                            else {
                                console.error("No job found");
                            }

                        }

                    }
                }}
                name="info-jobs-applicants" className="d-flex flex-row align-items-center mb-4" style={{ gap: "10px" }}>
                <Form.Item
                    name="job"
                    className="mb-0 disable-explain"
                    style={{ width: "300px" }}
                    rules={[{ required: true, message: false }]}
                >
                    <AutoComplete
                        placeholder={t("business_toolbox_page_motivated_candidates_filter_job")}
                        filterOption={(inputValue, option) => option && inputValue && option.label && option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
                        style={{ minWidth: 300, maxWidth: 250 }}
                        defaultValue={filterJob && filterJob.jobName}
                        options={filterJobNames ? [...filterJobNames] : []}
                    />
                </Form.Item>
                <Button className="btn btn-primary" style={{ minHeight: 48 }} htmlType="submit" >{t("business_toolbox_page_filter_see_result")}</Button>
            </Form>
            // <Select
            //     className="w-100" value={filterJob && filterJob.jobId}
            //     loading={jobsIsLoading || jobsIsRefetching} style={{ minWidth: 300, maxWidth: 250 }}
            //     onChange={(value) => setFilterJob(filterJobNames.find(x => x.jobId === value))}
            //     options={filterJobNames ? [...filterJobNames.map((item) => ({ label: item.jobName, value: item.jobId }))] : []}
            // />
        }
        {
            jobsIsError ? <NoData error /> :
                jobsIsLoading || jobsIsRefetching ? <Loading center /> :
                    !filterJobNames || filterJobNames.length === 0 ? <NoData /> :
                        !filterJob ? null :
                            <>
                                <div className="my-3">

                                    <Panel
                                        scroll
                                        scrollHeight={600}
                                        title={`${t("business_panel_questions_from_applicants_title")} ${jobMessages && jobMessages.length > 0 ? `(${jobMessages.length})` : ""}`}
                                    >
                                        {
                                            jobMessagesIsLoading || jobMessagesIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                                isError ? <NoData error /> : /*#VDG*/
                                                    !jobMessages || jobMessages.length <= 0 ? <div className="my-5"><NoData wise="sad" text={t("business_company_jobs_questions_nodata")} /> </div> :
                                                        (jobMessages).length === 0 ?
                                                            <div className="my-5"><NoData wise="search" text={t("business_company_jobs_questions_search_nodata")} /> </div> :
                                                            (jobMessages).map((item, i) => <Fragment key={i}>
                                                                <JobProbeQuestion
                                                                    data={item}
                                                                    refetch={jobMessagesRefetch}
                                                                    jobProbeOptionData={jobProbeOptions.find(option => option.value === item.jobProbeId)}
                                                                />
                                                            </Fragment>
                                                            )
                                        }
                                    </Panel>
                                </div>
                            </>
        }
    </>

}

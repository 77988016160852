import { useQuery } from "@tanstack/react-query";
import { getDateInFormat } from "components/utils/methods/MyMethods";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import moment from "moment";

export const useGetDbScoreEvolution = (propsFn) => {
    const userStore = useUserStore();
    const props = propsFn || {};

    return useQuery({
        queryKey: [props.key || 'getDbScoreEvolution'],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/scores/evolutions`,
            // route: `v3/business/select/businesses/${userStore.companyData.id}/dashboards/evolutions`,
            body: {
                CeKey: props.ceKey || null,
                Category: props.category || 'Information',
                WeekNotation: props.isWeekNotation || false,
                UseDivisions: props.useDivisions || false,
                BusinessId: userStore.companyData.id,
                FilterPhaseId: props.filterPhaseId || null,
                FilterUserId: props.filterUserId || null,
                FilterJobId: props.filterJob || null,
                FilterDate: props.filterDate ? getDateInFormat(props.filterDate, 'YYYY-MM-DD') : null,
            },
            callback: (res) => {
                if (res && res.data && res.data.instance && res.data.instance.length > 0 && res.data.status === 200) {
                    return res.data.instance;
                }
                else {
                    return [];
                }
            },
            customCallback: (res) => {
                if (props.setFilterDates && res && res.data.status === 200 && res.data.instance && res.data.instance.length > 0) {
                    const newData = (res.data.instance.sort((a, b) => a.sortIndex - b.sortIndex).map(item => {
                        const date = moment({ year: item.yearIndex, month: item.monthIndex }).format('MMM \'YY');
                        const rawDate = moment({ year: item.yearIndex, month: item.monthIndex }).format();
                        return {
                            label: date,
                            value: rawDate
                        }
                    }));
                    props.setFilterDates(newData.reverse());
                }
                if (props.extraCallback) {
                    props.extraCallback(res);
                }

            }
        }),
        refetchOnWindowFocus: false,
        enabled: props.enabled || false,
    })
}
import Panel from "components/modules/panel/panel";
import { t } from "i18next";

export default function CompetitionPanel(props) {
    const competitionPercentage = (choice) => props.data && props.data.competition && props.data.competition.length > 0 && props.data.competition.find(x => x.choice === choice) ? props.data.competition.find(x => x.choice === choice).percentage : 0;

    return <Panel
        title={t("business_panel_competition_title")}
    >
        <div className="d-flex flex-row flex-nowrap justify-content-between align-items-center py-2">
            <div className="mx-2" style={{ maxWidth: "180px", minWidth: "120px" }}>
                <h4 className="mb-1 color-lime fw-bold text-center" style={{ fontSize: "2.3rem" }}>{props.data && props.data.applicants ? props.data.applicants : "-"}</h4>
                <p className="text-center mb-0">{t("business_panel_competition_applicants")}</p>
            </div>
            <svg width="1" height="30" viewBox="0 0 1 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="0.5" y1="30" x2="0.499999" y2="2.18556e-08" stroke="#DCDCDC" />
            </svg>
            <div className="d-grid w-100 justify-content-center mx-2" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
                <div style={{ maxWidth: "150px" }}>
                    <h4 className="mb-1 color-lime fw-bold text-center" style={{ fontSize: "2.3rem" }}>{competitionPercentage(1)}%</h4>
                    <p className="text-center mb-0">{t("business_panel_competition_applicants_none")}</p>
                </div>
                <div style={{ maxWidth: "150px" }}>
                    <h4 className="mb-1 color-lime fw-bold text-center" style={{ fontSize: "2.3rem" }}>{competitionPercentage(2)}%</h4>
                    <p className="text-center mb-0">{t("business_panel_competition_applicants_1_or_2")}</p>
                </div>
                <div style={{ maxWidth: "150px" }}>
                    <h4 className="mb-1 color-lime fw-bold text-center" style={{ fontSize: "2.3rem" }}>{competitionPercentage(3)}%</h4>
                    <p className="text-center mb-0">{t("business_panel_competition_applicants_more_than_2")}</p>
                </div>
            </div>
        </div>
    </Panel>
}
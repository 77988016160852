import { useQuery } from '@tanstack/react-query';
import { Checkbox, Input, Modal, Select, Tag, Tooltip } from 'antd';
import axios from 'axios';
import PrefixIcon from 'components/forms/input/prefix-icon';
import GuestRedirectLayout from 'components/layouts/page-layouts/guest-redirect';
import useEditJob from 'components/requests/company/useEditJob';
import useGetCompanyTeam from 'components/requests/company/useGetCompanyTeam';
import { useUserStore } from 'components/utils/stores/userStore';
import { t } from 'i18next';
import Moment from 'moment';
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../../components/animations/loading";
import NoData from "../../components/layouts/no-data/nodata";
import AddJobOverlay from "../../components/layouts/overlays/add-job";
import ConfirmDeleteJobOverlay from "../../components/layouts/overlays/confirm-delete-job";
import EditJobOverlay from "../../components/layouts/overlays/edit-job";
import JobQuestionsOverlay from '../../components/layouts/overlays/job-questions';
import { handleRequestError, handleRequestSuccess, hasPermission, isValid } from "../../components/utils/methods/MyMethods";
import { sendAxiosPostRequest } from 'components/utils/methods/myRequestHandlers';
import JobProbeQuestionsOverlay from 'components/layouts/overlays/job-probe-questions';

export default function JobsPage(props) {
    // # STORES
    const userStore = useUserStore();

    // # UTILS 
    const navigate = useNavigate();

    // # CLIENT STATES 
    const [filterSearch, setFilterSearch] = useState(null);
    const [filterUserId, setFilterUserId] = useState(null);
    const [currentJobs, setCurrentJobs] = useState(false);
    const [filteredJobs, setFilteredJobs] = useState(null);
    const [overlayAdd, setOverlayAdd] = useState(false);
    const [overlayDelete, setOverlayDelete] = useState(false);
    const [overlayJobHighlight, setOverlayJobHighlight] = useState(false);
    const [overlayEdit, setOverlayEdit] = useState(false);
    const [overlayMessages, setOverlayMessages] = useState(false);
    const [overlayJobProbes, setOverlayJobProbes] = useState(false);
    const [jobItem, setJobItem] = useState(null);
    const [showUnansweredFilter, setShowUnansweredFilter] = useState(false);
    // TEAM LIST    
    const [team, setAllTeam] = useState(null);
    const [listTeam, setListTeam] = useState([]);
    // PERMISSIONS
    const [PM_JobView, setPM_JobView] = useState(false);
    const [PM_JobEdit, setPM_JobEdit] = useState(false);
    const [PM_JobDelete, setPM_JobDelete] = useState(false);
    const [PM_JobUpload, setPM_JobUpload] = useState(false);

    // # SERVER STATES
    const { } = useGetCompanyTeam({ setData: setAllTeam })
    const { data: rawjobsdata, refetch: jobsRefetch, isLoading: jobsIsLoading, isRefetching: jobsIsRefetching, isError } = useGetJobs({ setData: setCurrentJobs })
    const mutateJob = useEditJob({ setOverlay: props.setOverlay, getJobs: jobsRefetch });

    useEffect(() => {
        if (!userStore.userData) { return }
        hasPermission(userStore.userData.permissionsData, "PM_VIEW_JOBS", setPM_JobView, navigate);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_JOBS", setPM_JobEdit);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_JOBS", setPM_JobDelete);
        hasPermission(userStore.userData.permissionsData, "PM_EDIT_JOBS", setPM_JobUpload);
    }, [userStore.userData]);

    function handleDate(date) {
        var dateFormat = new Moment(date);
        var local = dateFormat.utc(date).local();

        return local.format("DD/MM/YYYY");
    }

    function handleAssignedTo(assignedTo, job) {
        var teamList = [];
        var threshold = 25;
        if (assignedTo === null) { return "-" }
        if (assignedTo[0] === null) { return "-" }
        if (assignedTo.length <= 0) { return "-" }

        assignedTo.map((assignedUser, i) => {
            var newMember = team.filter(teammate => assignedUser === teammate.userId);
            teamList.push(newMember[0])
        });

        var list = "";

        teamList.sort((a, b) => a.firstName > b.firstName ? 1 : -1).map((item, i) => {
            if (item === undefined) { return }
            if ((teamList).length - 1 === (i)) {
                return list = list + item.firstName + " " + item.lastName;
            } else {
                return list = list + item.firstName + " " + item.lastName + ", ";
            }
        })


        if (list.length > threshold) {
            list = list.substring(0, threshold) + "...";
        };

        return list;
    }

    function addYourself(job) {
        if (!isValid([job], [undefined, null])) { return }
        console.log(job)

        setCurrentJobs(false);

        var newAssigned = job.assignedToUserIds;

        if (newAssigned === null) {
            newAssigned = [userStore.userData.id];
        } else {
            newAssigned = [...newAssigned, userStore.userData.id];
        }
        mutateJob.mutate({
            jobName: job.jobName,
            formObject: { assignedTo: newAssigned, name: job.jobName, status: job.jobIsClosed, highlighted: job.jobIsHighlight },
            assignedTo: newAssigned,
            isClosed: job.jobIsClosed,
            isHighlight: job.jobIsHighlight,
            jobId: job.jobId,
            addYourself: true,
            getJobs: jobsRefetch
        })

    }

    function removeYourself(job) {
        var isValidJob = isValid([job], [undefined, null]);

        if (!isValidJob) { return }
        if (job.assignedToUserIds === null) { return }

        setCurrentJobs(false);

        var newAssigned = job.assignedToUserIds;
        newAssigned = job.assignedToUserIds.filter(item => item !== userStore.userData.id);

        mutateJob.mutate({
            jobName: job.jobName,
            assignedTo: newAssigned,
            isClosed: job.jobIsClosed,
            isHighlight: job.jobIsHighlight,
            jobId: job.jobId,
            getJobs: jobsRefetch
        })

    }

    function toggleHighlight(job) {
        if (!isValid([job], [undefined, null])) { return }
        console.log(job)

        setCurrentJobs(false);

        mutateJob.mutate({
            jobName: job.jobName,
            assignedIds: job.assignedToUserIds,
            isHighlight: !job.jobIsHighlight,
            isClosed: job.jobIsClosed,
            jobId: job.jobId,
            getJobs: jobsRefetch
        })
    }

    function toggleStatus(job) {
        if (!isValid([job], [undefined, null])) { return }

        setCurrentJobs(false);
        console.log(job)
        mutateJob.mutate({
            // formObject: { name: jobName, status: !job.jobIsClosed === false ? 0 : 1 },
            jobName: job.jobName,
            assignedIds: job.assignedToUserIds,
            isClosed: !job.jobIsClosed === false ? 0 : 1,
            jobId: job.jobId,
            isHighlight: job.jobIsHighlight,
            getJobs: jobsRefetch
        })
    }
    useEffect(() => {
        if (!currentJobs || currentJobs === false) {
            setFilteredJobs(null);
            return;
        }
        if (currentJobs) {

            let jobs = [...currentJobs];

            // Sorting jobs
            jobs.sort((a, b) => new Date(b.jobCreatedOn) - new Date(a.jobCreatedOn));
            jobs.sort((a, b) => a.jobIsClosed - b.jobIsClosed);

            // Filtering by search
            if (filterSearch) {
                jobs = jobs.filter(item =>
                    item.jobName.toLowerCase().includes(filterSearch.toLowerCase())
                );
            }

            // Filtering unanswered jobs
            if (showUnansweredFilter) {
                jobs = jobs.filter(item => item.noProbeReplyCount > 0);
            }

            // Filtering unanswered jobs
            if (filterUserId) {
                jobs = jobs.filter(item => item.assignedToUserIds && item.assignedToUserIds.includes(filterUserId))
            }

            setFilteredJobs(jobs);
        }
    }, [currentJobs, filterSearch, showUnansweredFilter, rawjobsdata, filterUserId]);


    useEffect(() => {
        if (team === null) { return }
        var newTeam = [];

        team.map(item => {
            if (item.firstName === null) { return }
            newTeam.push({
                value: item.userId,
                label: !item.lastName ? item.firstName : `${item.firstName} ${item.lastName}`
            });
        })

        if (newTeam.length !== 0) {
            setListTeam(newTeam);
        }
    }, [team]);


    return (
        <GuestRedirectLayout redirectSmallSubsciption>
            <div id="jobs">
                <div className="container-page pb-0">
                    <JobProbeQuestionsOverlay setCurrentJobs={setCurrentJobs} getJobs={jobsRefetch} jobItem={jobItem} setOverlay={setOverlayJobProbes} overlayState={overlayJobProbes} />
                    <JobQuestionsOverlay setCurrentJobs={setCurrentJobs} getJobs={jobsRefetch} jobItem={jobItem} setOverlay={setOverlayMessages} overlayState={overlayMessages} />
                    <AddJobOverlay team={listTeam} getJobs={jobsRefetch} setOverlay={setOverlayAdd} overlayState={overlayAdd} />
                    <ConfirmDeleteJobOverlay setCurrentJobs={setCurrentJobs} getJobs={jobsRefetch} jobItem={jobItem} setOverlay={setOverlayDelete} overlayState={overlayDelete} />
                    <EditJobOverlay setCurrentJobs={setCurrentJobs} team={listTeam} getJobs={jobsRefetch} jobItem={jobItem} setOverlay={setOverlayEdit} overlayState={overlayEdit} />

                    <Modal
                        closable
                        onCancel={() => setOverlayJobHighlight(false)}
                        title={t("business_jobs_highlight_overlay_title")}
                        open={overlayJobHighlight}
                        okButtonProps={{ style: { display: "none" } }}
                        cancelButtonProps={{ style: { display: "none" } }}
                    >
                        {t("business_jobs_highlight_overlay_description")}
                    </Modal>
                    <div className="container">
                        <div className="page-content">
                            <h1 className="title">{t("business_main_jobs")}</h1>
                            <p className="subtitle">{t("business_jobs_subtitle")}</p>
                            {
                                !PM_JobView ? null :
                                    <div className="mt-4 mb-10">
                                        <div>
                                            <div className="card card-scroll">
                                                <div className="container">
                                                    <div className="d-flex justify-content-between flex-wrap">
                                                        <div className="panel-actions mb-2 flex flex-row align-items-center" style={{ gap: 5 }}>
                                                            <div className="d-inline-block">
                                                                <Input
                                                                    placeholder={t("business_main_search")}
                                                                    prefix={<PrefixIcon value="icon-search" />}
                                                                    onChange={(e) => setFilterSearch(e.target.value.toLowerCase())}
                                                                />
                                                            </div>
                                                            <Select
                                                                className="d-inline-block"
                                                                defaultValue={null}
                                                                style={{ minWidth: 300 }}
                                                                onChange={(val) => setFilterUserId(val)}
                                                                options={[{ label: t("business_dashboard_filter_select_all_recruiters"), value: null },
                                                                { label: t("business_dashboard_filter_select_no_recruiters"), value: false }, ...listTeam]}
                                                            />
                                                            <Checkbox
                                                                value={showUnansweredFilter}
                                                                defaultChecked={false}
                                                                className='mx-4'
                                                                onChange={(e) => setShowUnansweredFilter(e.target.checked)} >{t("business_jobs_filter_unanswered")}</Checkbox>
                                                        </div>
                                                        {
                                                            !PM_JobUpload ? null :
                                                                <div>
                                                                    <Link to="#" onClick={() => setOverlayAdd(true)} className="btn btn-lg btn-primary d-inline-block">
                                                                        {t("business_jobs_panel_upload")}
                                                                    </Link>
                                                                </div>
                                                        }
                                                    </div>
                                                </div>

                                                <div className="scroll">
                                                    <div id="manage-table" className="custom container">
                                                        <div className={"manage-table jobs-table"}>
                                                            {/* <div className={!permissionEditJobs ? "manage-table jobs-table " + permissionEditJobs : "manage-table jobs-table2 " + permissionEditJobs}> */}
                                                            <div className="head">
                                                                <span>
                                                                    {t("business_main_name")}
                                                                </span>
                                                                <span>
                                                                    {t("business_main_upload_date")}
                                                                </span>
                                                                <span>
                                                                    {t("business_main_assigned_to")}
                                                                </span>
                                                                <div className="actions">
                                                                    <span>
                                                                        {t("business_main_jobstatus")}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {
                                                                jobsIsLoading || jobsIsRefetching ? <div className="d-flex justify-content-center"><Loading text={null} big /></div> :
                                                                    isError ? <NoData error /> /*#VDG */ :
                                                                        !filteredJobs ? <div className="my-4"><NoData wise="cross" /></div> :
                                                                            filteredJobs.length === 0 ? <div className="my-4"><NoData wise="search" /></div> :
                                                                                Array.from(filteredJobs).map((item, i) => (
                                                                                    <div id={"job-" + i} className="item">
                                                                                        <span>
                                                                                            {
                                                                                                item.jobName === undefined ? "-" :
                                                                                                    item.jobName === null ? "-" :
                                                                                                        item.jobName
                                                                                            }
                                                                                        </span>
                                                                                        <span className="font-special" >
                                                                                            {
                                                                                                item.jobCreatedOn === undefined ? "-" :
                                                                                                    item.jobCreatedOn === null ? "-" :
                                                                                                        handleDate(item.jobCreatedOn)
                                                                                            }
                                                                                        </span>
                                                                                        <span className="font-special">
                                                                                            {
                                                                                                team === null ? "-" :
                                                                                                    item.assignedToUserIds === undefined ? "-" :

                                                                                                        handleAssignedTo(item.assignedToUserIds, item)
                                                                                            }
                                                                                        </span>
                                                                                        {
                                                                                            PM_JobEdit ?
                                                                                                null :
                                                                                                <div className="">
                                                                                                    <span className="role">
                                                                                                        <Select
                                                                                                            bordered={false}
                                                                                                            onSelect={(value, option) => toggleStatus(item)}
                                                                                                            options={[
                                                                                                                { value: 0, label: t("business_main_open") },
                                                                                                                { value: 1, label: t("business_main_closed") },
                                                                                                            ]}
                                                                                                            value={!item.jobIsClosed ? 0 : 1}
                                                                                                            disabled={true}
                                                                                                        />
                                                                                                    </span>
                                                                                                </div>
                                                                                        }
                                                                                        {
                                                                                            PM_JobEdit ?
                                                                                                <div className="actions">
                                                                                                    <span className="role">
                                                                                                        <Select
                                                                                                            bordered={false}
                                                                                                            onSelect={(value, option) => toggleStatus(item)}
                                                                                                            options={[
                                                                                                                { value: 0, label: t("business_main_open") },
                                                                                                                { value: 1, label: t("business_main_closed") },
                                                                                                            ]}
                                                                                                            value={!item.jobIsClosed ? 0 : 1}
                                                                                                        />
                                                                                                    </span>
                                                                                                    {/* <div className="mr-3">
                                                                                                        {
                                                                                                            item.jobIsClosed ? null :
                                                                                                                <Tooltip title={t("business_tooltip_jobs_highlight")}>
                                                                                                                    <span
                                                                                                                        onClick={() => setOverlayJobHighlight(true)}
                                                                                                                        className={`default icon icon-nospace ${!item.jobIsHighlight ? 'icon-star-empty' : 'icon-star'}`}
                                                                                                                    />
                                                                                                                </Tooltip>
                                                                                                        }
                                                                                                    </div> */}
                                                                                                    <div className="mr-3">
                                                                                                        <Tooltip title={t("business_company_job_questions_probe_tooltip")}>
                                                                                                            <span
                                                                                                                onClick={() => { setOverlayJobProbes(true); setJobItem(item) }}
                                                                                                                className={"default icon-qna icon messages icon-nospace"}
                                                                                                            >
                                                                                                                {!item.noProbeReplyCount ? null : item.noProbeReplyCount < 1 ? null : <span className="messages-small">{item.noProbeReplyCount >= 10 ? "9+" : item.noProbeReplyCount}</span>}
                                                                                                            </span>
                                                                                                        </Tooltip>
                                                                                                    </div>
                                                                                                    {/* <svg className="mr-3" width="6" height="2" viewBox="0 0 6 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <line x1="5" y1="1" x2="1" y2="1" stroke="#808080" stroke-opacity="0.94" stroke-width="2" stroke-linecap="round" />
                                                                                                    </svg> */}

                                                                                                    {
                                                                                                        item.assignedToUserIds === null ?
                                                                                                            <div className="mr-3">
                                                                                                                <Tooltip title={t("business_tooltip_jobs_assign")}>
                                                                                                                    <span onClick={() => { addYourself(item) }} className="default icon-plus-round2 icon icon-nospace" />
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                            :
                                                                                                            item.assignedToUserIds.filter(job => job === userStore.userData.id).length > 0 ?
                                                                                                                <div className="mr-3">
                                                                                                                    <Tooltip title={t("business_tooltip_jobs_unassign")}>
                                                                                                                        <span onClick={() => { removeYourself(item) }} className="default icon-cross icon icon-nospace" />
                                                                                                                    </Tooltip>
                                                                                                                </div>
                                                                                                                :
                                                                                                                <div className="mr-3">
                                                                                                                    <Tooltip title={t("business_tooltip_jobs_assign")}>
                                                                                                                        <span onClick={() => { addYourself(item) }} className="default icon-plus-round2 icon icon-nospace" />
                                                                                                                    </Tooltip>
                                                                                                                </div>
                                                                                                    }

                                                                                                    {
                                                                                                        !PM_JobEdit ? null :
                                                                                                            <div className="mr-3">
                                                                                                                <Tooltip title={t("business_tooltip_jobs_edit")}>
                                                                                                                    <span onClick={() => { setOverlayEdit(true); setJobItem(item) }} className="default icon-edit icon icon-nospace" />
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                    }
                                                                                                    {
                                                                                                        !PM_JobDelete ? null :
                                                                                                            <div className="mr-3">
                                                                                                                <Tooltip title={t("business_tooltip_jobs_delete")}>
                                                                                                                    <span onClick={() => { setOverlayDelete(true); setJobItem(item) }} className="default icon-delete icon icon-nospace" />
                                                                                                                </Tooltip>
                                                                                                            </div>
                                                                                                    }
                                                                                                </div>
                                                                                                :
                                                                                                <div className="actions">
                                                                                                </div>
                                                                                        }
                                                                                    </div>
                                                                                ))

                                                            }


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </GuestRedirectLayout>
    );
}

export const useGetJobs = (props) => {
    const userStore = useUserStore();
    // return useQuery({
    //     queryKey: ["jobsRefetch"],
    //     queryFn: () => sendAxiosPostRequest({
    //         route: `v2/companies/${userStore.companyData.id}/jobs`,
    //         callback: (res) => {
    //             props.setData(res.data.instance);
    //             return res.data.instance
    //         }, customCallback: (res) => {
    //             switch (parseInt(res.data.status)) {
    //                 case 290:
    //                     props.setData(null);
    //                     break;
    //                 default: break;
    //             }
    //             return res.data.instance
    //         }
    //     }),
    //     refetchOnWindowFocus: false,
    //     retry: 2,
    // })
    return useQuery({
        queryKey: ["jobsRefetch"],
        queryFn: () => sendAxiosPostRequest({
            route: `v2/companies/${userStore.companyData.id}/jobs`,
            body: {
                UserId: userStore.userData.id
            },
            callback: (res) => {
                if (res.data.instance && Array.isArray(res.data.instance) && res.data.instance.length > 0) {
                    const jobs = res.data.instance;
                    jobs.sort((a, b) => new Date(b.jobCreatedOn) - new Date(a.jobCreatedOn));
                    jobs.sort((a, b) => a.jobIsClosed - b.jobIsClosed);
                    if (props.setData) props.setData(jobs);
                    if (props.extraCallback) props.extraCallback(jobs);
                    return res.data.instance
                }
                else {
                    if (props.setData) props.setData([]);
                    if (props.extraCallback) props.extraCallback([]);
                    return []
                }
            }, customCallback: (res) => {
                switch (parseInt(res.data.status)) {
                    case 290:
                        props.setData(null);
                        break;
                    default: break;
                }
                return res.data.instance
            }
        }),
        refetchOnWindowFocus: false,
        retry: 2,
        onError: (res) => handleRequestError({ response: res }),
    })
}
import { useUserStore } from "components/utils/stores/userStore";
import { t } from 'i18next';
import { useEffect, useState } from "react";
import GuestRedirectLayout from "../components/layouts/page-layouts/guest-redirect";
import { ChartNoAxesCombinedIcon, CircleHelpIcon, CircleUserRoundIcon, EllipsisVerticalIcon, FileUser, UsersRoundIcon } from "lucide-react";
import { ChevronRight } from "react-feather";
import ToolboxRender from "components/layouts/toolbox/toolboxRender";
import { useSearchParams } from "react-router-dom";

export default function WisePage(props) {
    // # STORES
    const userStore = useUserStore();
    const isAgency = userStore.companyData.isAgency;
    let [searchParams, setSearchParams] = useSearchParams();
    const currentContent = searchParams.get("content");
    const agencyTeasers = [
        {
            label: "business_toolbox_motivated_candidates",
            teaser: "business_toolbox_teaser_motivated_candidates_agency",
            icon: <UsersRoundIcon color="white" />,
            value: "motivated_candidates"
        },
        {
            label: "business_toolbox_my_jobs_questions_agency",
            teaser: "business_toolbox_teaser_my_jobs_questions_agency",
            icon: <CircleHelpIcon color="white" />,
            value: "agency_jobs_questions"
        },
        {
            label: "business_toolbox_info_self",
            teaser: "business_toolbox_teaser_info_self_agency",
            icon: <CircleUserRoundIcon color="white" />,
            value: "info_self"
        },
        {
            label: "business_toolbox_smart_insights_business_impact_agency_title",
            teaser: "business_toolbox_teaser_smart_insights_business_impact_agency",
            icon: <ChartNoAxesCombinedIcon color="white" />,
            value: "agency_smart_insights"
        },
        {
            label: "business_toolbox_smart_sorting_agency",
            teaser: "business_toolbox_teaser_smart_sorting_agency",
            icon: <EllipsisVerticalIcon color="white" />,
            value: "agency_smart_sorting"
        }
    ];

    const regularTeasers = [
        {
            label: "business_toolbox_motivated_candidates",
            teaser: "business_toolbox_teaser_motivated_candidates",
            icon: <UsersRoundIcon color="white" />,
            value: "motivated_candidates"
        },
        {
            label: "business_toolbox_info_jobs_and_applicants",
            teaser: "business_toolbox_teaser_info_jobs_and_applicants",
            icon: <FileUser color="white" />,
            value: "info_jobs_and_applicants"
        },
        {
            label: "business_toolbox_info_self",
            teaser: "business_toolbox_teaser_info_self",
            icon: <CircleUserRoundIcon color="white" />,
            value: "info_self"
        },
        {
            label: "business_toolbox_smart_analytics_insights",
            teaser: "business_toolbox_teaser_smart_analytics_insights",
            icon: <ChartNoAxesCombinedIcon color="white" />,
            value: "smart_analytics_insights"
        },
        {
            label: "business_toolbox_smart_sorting",
            teaser: "business_toolbox_teaser_smart_sorting",
            icon: <EllipsisVerticalIcon color="white" />,
            value: "smart_sorting"
        }
    ];

    const [teasers, setTeasers] = useState(isAgency ? agencyTeasers : regularTeasers);

    // START AT TOP OF PAGE ON LOAD
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <GuestRedirectLayout>
            <div id="wise">
                <div className="container-page pb-0">
                    <div className="container">
                        <div className="page-content mt-0" style={{ minHeight: "unset" }}>
                            {
                                currentContent && teasers.find(teaser => teaser.value === currentContent) ?
                                    <>
                                        <ToolboxRender type={currentContent} label={teasers.find(teaser => teaser.value === currentContent) && t(teasers.find(teaser => teaser.value === currentContent).label)} />
                                    </> :
                                    <>
                                        <div className="d-flex justify-content-between">
                                            <h1 className='title'>👋 {t("business_main_greet")}{!userStore.userData ? null : userStore.userData.firstName === null ? null : ", " + userStore.userData.firstName}</h1>
                                        </div>
                                        <p className="subtitle">{t("business_hero_subtitle")}</p>
                                        <div className="d-flex flex-column" style={{ gap: "10px" }}>
                                            {
                                                teasers && teasers.map((teaser, index) => (
                                                    <div key={index} onClick={() => setSearchParams({ content: teaser.value })}>
                                                        <div className="accordion-custom">
                                                            <div className="accordion-head" >
                                                                <div className="d-flex flex-row align-items-center flex-wrap">
                                                                    <div className="fs-xregular">
                                                                        <div className="pr-3 " style={{ position: "relative", display: "inline-block" }}>
                                                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                <circle cx="17.5" cy="26.5" r="17.5" fill="#1B6A63" />
                                                                                <g filter="url(#filter0_d_3214_614)">
                                                                                    <circle cx="24" cy="20" r="20" fill="#18A89B" />
                                                                                </g>
                                                                                <defs>
                                                                                    <filter id="filter0_d_3214_614" x="0" y="0" width="48" height="48" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                                                        <feOffset dy="4" />
                                                                                        <feGaussianBlur stdDeviation="2" />
                                                                                        <feComposite in2="hardAlpha" operator="out" />
                                                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3214_614" />
                                                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3214_614" result="shape" />
                                                                                    </filter>
                                                                                </defs>
                                                                            </svg>
                                                                            <span style={{ position: "absolute", top: "38%", left: "38%", transform: "translate(-50%, -50%)", zIndex: 1 }}>
                                                                                {teaser.icon}
                                                                            </span>


                                                                        </div>
                                                                        {t(teaser.teaser)}
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <ChevronRight size={20} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </GuestRedirectLayout>
    );
}
import { useQuery } from "@tanstack/react-query";
import { useRememberMe } from "App";
import { isAxiosError } from "axios";
import Loading from "components/animations/loading";
import NoData from "components/layouts/no-data/nodata";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function AccessPage() {
    const [searchParams, _] = useSearchParams()
    const [token, setToken] = useState();
    const userStore = useUserStore();
    const navigate = useNavigate();
    const { refetch: rememberMeRefetch, isFetching: rememberMeIsFetching, isSuccess: rememberMeIsSuccess, isError: rememberMeIsError } = useRememberMe({ token: token });

    const { isLoading, isError, isSuccess } = useQuery({
        queryKey: ['access-api-key'],
        queryFn: () => sendAxiosPostRequest({
            route: `/login/ats`,
            body: {
                Recruiter: searchParams.get('recruiter'),
                ApiKey: searchParams.get('code')
            },
            customCallback: (res) => {
                if (res && !isAxiosError(res) && res.data && res.data.status) {
                    switch (res.data.status) {
                        case 400:
                            navigate("/login?ref=ats-token-400")
                            break;
                        case 480:
                            navigate("/login?ref=ats-token-480")
                            break;
                        case 483:
                            navigate("/login?ref=ats-token-483")
                            break;
                        case 200:
                            if (res && !isAxiosError(res) && res.data.instance && res.data.instance.tokenData && res.data.status === 200) {
                                const token = res.data.instance.tokenData.token;
                                setToken(token);
                            }
                            break;
                        default: break;
                    }
                }
            }
        }),
        enabled: !!searchParams.get('recruiter') && !!searchParams.get('code'),
    })

    useEffect(() => {
        if (token) rememberMeRefetch()
    }, [token])

    useEffect(() => {
        if (userStore && userStore.userData && userStore.userData.id && rememberMeIsSuccess && isSuccess) navigate("/wise")
        else { userStore.logout({}) }
    }, [userStore.userData])

    if (!searchParams.get('recruiter') || !searchParams.get('code')) return <div style={{ minHeight: "80vh" }}><NoData className="my-5" error /></div>
    if (isLoading || rememberMeIsFetching) return <Loading fullscreen center />
    if (isError || rememberMeIsError) return <div style={{ minHeight: "80vh" }}><NoData className="my-5" error /></div>
    return (
        <Loading text={null} big fullscreen center />
    );
}
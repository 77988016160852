import { Empty, Modal, Select, Table, Tag } from "antd";
import AccordionContent from "components/layouts/accordions/accordionContent";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel from "components/layouts/dashboard/panels/messages-panel";
import ScoreEvolutionPanel from "components/layouts/dashboard/panels/score-evolution-panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { useUserStore } from "components/utils/stores/userStore";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { adminRoleId } from "components/utils/methods/AppMethods";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useQuery } from "@tanstack/react-query";
import Rating from "components/modules/rating/rating";
import { Link } from "react-router-dom";
import { DemoPanelDedicatedApplicants, PanelDedicatedApplicants } from "components/layouts/dashboard/basic/db-premium";
import { useGetJobs } from "pages/jobs/jobs";


export default function InfoSelfBlock(props) { // # STORES
    const userStore = useUserStore();
    const { t } = useTranslation()
    const isAdmin = userStore.userData && userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;
    const [generalLabel, setGeneralLabel] = useState(null);
    const [filterUserId, setFilterUserId] = useState(!isAdmin ? userStore.userData.id : null);
    const [filterPhaseId, setFilterPhaseId] = useState(null);
    const [filterDate, setFilterDate] = useState(null);
    const [filterDates, setFilterDates] = useState(null);
    const [filterJob, setFilterJob] = useState(null);
    const [filterJobs, setFilterJobs] = useState(null);
    const [showCeKeysModal, setShowCeKeysModal] = useState(false);
    const { refetch: jobsRefetch, isLoading: jobsIsLoading, isRefetching: jobsIsRefetching, isError } = useGetJobs({ setData: setFilterJobs })

    // # SERVER STATES
    // base data
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)

    const { data: dbScoreEvolution, isLoading: dbScoreEvolutionIsLoading, isError: dbScoreEvolutionIsError, refetch: dbScoreEvolutionRefetch, isRefetching: dbScoreEvolutionIsRefetching } = useGetDbScoreEvolution({ filterPhaseId, filterJob, filterDate, filterUserId, category: "General", key: "getScore-BC_General", enabled: true, setFilterDates: !filterDate ? setFilterDates : null });

    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId, filterJob, filterUserId, enabled: true });

    const { data: applicantsInsightData, isLoading: applicantsInsightDataIsLoading, isError: applicantsInsightDataIsError, refetch: applicantsInsightRefetch } = useQuery({
        queryKey: ["applicantsInsights"],
        queryFn: () => sendAxiosPostRequest({
            route: `v6/select/insights/job/applicants`,
            body: {
                UserId: filterUserId ? filterUserId : userStore.userData.id,
                JobId: filterJob ? filterJob : null,
                BusinessId: userStore.companyData.id
            },
        }),
    })

    const { data: recruitersData, isLoading: recruitersDataIsLoading, isSuccess: recruitersDataIsSuccess } = useQuery({
        cacheTime: 0,
        queryKey: ["dbGetAllRecruiters"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/recruiters`,
            body: {
                BusinessId: userStore.companyData.id
            },
            callback: (res) => {
                if (res && res.data && res.data.status === 290) { return [] }
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    var newArray = []
                    res.data.instance.map((item) => {
                        if (isAdmin) {
                            newArray.push({
                                label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                value: item.id,
                                raw: item
                            })
                        } else {
                            if (item.id === userStore.userData.id) {
                                setFilterUserId(item.id);
                                newArray.push({
                                    label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                    value: item.id,
                                    raw: item
                                })
                            }
                        }
                    })

                    return newArray;
                }
                else {
                    return []
                }
            }
        })
    })

    const [isSticky, setIsSticky] = useState(false);
    const targetRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const targetElement = targetRef.current;
            if (targetElement) {
                const { top } = targetElement.getBoundingClientRect();
                setIsSticky(top <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const { data: phasesData, isLoading: phasesDataIsLoading } = useQuery({
        queryKey: ["dbGetAllPhases"],
        queryFn: () => sendAxiosGetRequest({
            route: `v2/generic/catalogues/JobPhases`,
            callback: (res) => {

                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    return res.data.instance.filter((item) => {
                        if (item.isAgency && userStore.companyData.isAgency) {
                            return item
                        }
                        if (!item.isAgency && !userStore.companyData.isAgency) {
                            return item
                        }
                    }).map(i => {
                        return {
                            label: t(i.translationKey),
                            value: i.id,
                            raw: i
                        }
                    })
                }
            }
        })
    })
    const { data: ceKeysData, isLoading: ceKeysDataIsLoading } = useQuery({
        queryKey: ["dbGetAllCEKeys"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/agencies`,
            body: {
                BusinessId: userStore.companyData.id
            },
        }),
        enabled: !!(userStore.companyData && userStore.companyData.id),
    });



    useEffect(() => {
        if (recruitersDataIsSuccess) {
            refetch();
            dbScoreEvolutionRefetch();
            dbMessagesRefetch();
            applicantsInsightRefetch();
        }
    }, [filterUserId, filterPhaseId, filterDate, recruitersDataIsSuccess, filterJob]);

    return <>
        <>
            <Modal
                open={showCeKeysModal}
                onCancel={() => setShowCeKeysModal(false)}
                title={t("business_dashboard_ce_keys_modal_title")}
                footer={null}
            >
                <>
                    <div className="flex flex-column align-items-start" style={{ gap: 5 }}>
                        {
                            ceKeysData && ceKeysData.length > 0 && ceKeysData.map((item, index) => {
                                return <Link key={index} to={`/ce-dashboard?id=${item.id}`} className="link mb-0">{item.name}</Link>
                            })
                        }

                    </div>
                </>
            </Modal>
            <div className="row relative">

                {/* {
                    dbGeneralData && <div className="flex flex-row align-items-start justify-content-between mb-4"><div className="flex flex-column align-items-start" style={{ gap: 10 }}>
                        <div className="flex flex-row align-items-center" style={{ gap: 10 }}>
                            <h2 className="mb-0 fw-bold">{dbGeneralData && dbGeneralData.totalCount ? dbGeneralData.totalCount : "-"} {t("business_dashboard_total_score")}</h2>
                            <Rating rating={!dbGeneralData ? 0 : !dbGeneralData.scoreGeneral ? null : dbGeneralData.scoreGeneral} noamount />
                        </div>
                        {
                            isAdmin && ceKeysData && ceKeysData.length > 0 && <p
                                className="link mb-0 inline-block"
                                onClick={() => setShowCeKeysModal(true)}>
                                {t("business_dashboard_show_ce_keys_modal")}
                            </p>
                        }
                    </div>
                    </div>
                } */}
                <div ref={targetRef} style={isSticky ? { height: 50 } : { height: 0 }} ></div>
                <div className={`mb-4 ${isSticky ? 'fixed py-3 bg-lime ' : ''}`} style={isSticky ? { top: 0, left: 0, zIndex: 100, width: "100vw", maxWidth: "100vw !important", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" } : { gap: 5 }}>
                    <div className={`flex flex-row-reverse align-items-center flex-nowrap ${isSticky ? 'container' : ''}`} style={{ gap: 5 }}>
                        {
                            recruitersData && isAdmin &&
                            <Select
                                className="w-100"
                                value={filterUserId}
                                style={{ minWidth: 300 }}
                                loading={recruitersDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterUserId(null);
                                    else setFilterUserId(value);
                                }}
                                options={recruitersData ? [{ label: t("business_dashboard_filter_select_all_recruiters"), value: null }, ...recruitersData] : []}
                            />
                        }
                        {
                            phasesData &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={phasesDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterPhaseId(null);
                                    else setFilterPhaseId(value);
                                }}
                                options={phasesData ? [{ label: t("business_dashboard_filter_select_all_phases"), value: null }, ...phasesData] : []}
                            />
                        }
                        {
                            filterDates && filterDates.length > 0 &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={recruitersDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterDate(null);
                                    else setFilterDate(value);
                                }}
                                options={filterDates ? [{ label: t("business_dashboard_filter_select_all_dates"), value: null }, ...filterDates] : []}
                            />
                        }
                        {
                            filterJobs && filterJobs.length > 0 &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={recruitersDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterJob(null);
                                    else setFilterJob(value);
                                }}
                                options={filterJobs ? [{ label: t("business_dashboard_filter_select_all_jobs"), value: null }, ...filterJobs.map((item) => ({ label: item.jobName, value: item.jobId }))] : []}
                            />
                        }
                    </div>
                </div>
            </div>
            {/* ### APPLICANT EXPERIENCE ###  */}
            <section>
                <div className="d-flex flex-row align-items-center mb-4" style={{ gap: 20 }}>
                    <h1 className="mb-0">{t("business_toolbox_teaser_info_self_title", { amount: applicantsInsightData && applicantsInsightData.applicants ? applicantsInsightData.applicants : "" })}:
                    </h1>
                    {
                        generalLabel && <span style={{ transform: "scale(1.2)" }}>{generalLabel}</span>
                    }

                </div>
                <>
                    <div className="row">
                        <div className="col gx-2">
                            <CandidateBrandExperiencePanel
                                setGeneralLabel={setGeneralLabel}
                                filterPhaseId={filterPhaseId}
                                filterUserId={filterUserId}
                                filterDate={filterDate}
                                filterJob={filterJob}
                                catFilter={(item) => item === 10}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col gx-2">
                            <ScoreEvolutionPanel
                                data={!dbScoreEvolution ? null : [
                                    {
                                        id: t("business_dashboard_average_score"),
                                        data: dbScoreEvolution
                                    },
                                ]}
                                panelProps={{
                                    isLoading: (dbScoreEvolutionIsLoading),
                                    isError: (dbScoreEvolutionIsError),
                                    isRefetching: (dbScoreEvolutionIsRefetching),
                                }}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col gx-2">
                            <MessagesPanel
                                data={dbMessages}
                                panelProps={{
                                    isLoading: dbMessagesIsLoading || dbMessagesIsRefetching, isError: dbMessagesIsError,
                                    isRefetching: dbMessagesIsRefetching,
                                }}
                            />
                        </div>
                    </div>
                </>

            </section>
        </>
    </>

}

import { useQuery } from "@tanstack/react-query";
import { Select } from "antd";
import { DemoPanelDedicatedApplicants } from "components/layouts/dashboard/basic/db-premium";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel, { DemoCandidateBrandExperiencePanel } from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel, { DemoMessagesPanel } from "components/layouts/dashboard/panels/messages-panel";
import DashboardRecruitment from "components/layouts/dashboard/recruitment/db-recruitment";
import GuestRedirectLayout from "components/layouts/page-layouts/guest-redirect";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { adminRoleId } from "components/utils/methods/AppMethods";
import { handleAllPermissions } from "components/utils/methods/MyMethods";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function RecruitmentDashboardPage2(props) {
    const userStore = useUserStore();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);

    const [isPremiumPlan, setIsPremiumPlan] = useState(null);
    const isAdmin = userStore.userData && userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;
    const [PM_DashboardView, setPM_DashboardView] = useState(false);
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: dbMessages, isLoading: dbMessagesIsLoading, isError: dbMessagesIsError, isRefetching: dbMessagesIsRefetching, refetch: dbMessagesRefetch } = useGetDbMessages({ filterPhaseId: null, filterUserId: null, enabled: true });


    const { data: recruitersData, isLoading: recruitersDataIsLoading, isSuccess: recruitersDataIsSuccess } = useQuery({
        cacheTime: 0,
        queryKey: ["dbGetAllRecruiters"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/recruiters`,
            body: {
                BusinessId: userStore.companyData.id
            },
            callback: (res) => {
                if (res && res.data && res.data.status === 290) { return [] }
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    var newArray = []
                    res.data.instance.map((item) => {
                        if (isAdmin) {
                            newArray.push({
                                label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                value: item.id,
                                raw: item
                            })
                        } else {
                            if (item.id === userStore.userData.id) {
                                newArray.push({
                                    label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                    value: item.id,
                                    raw: item
                                })
                            }
                        }
                    })

                    return newArray;
                }
                else {
                    return []
                }
            }
        })
    })


    const { data: phasesData, isLoading: phasesDataIsLoading } = useQuery({
        queryKey: ["dbGetAllPhases"],
        queryFn: () => sendAxiosGetRequest({
            route: `v2/generic/catalogues/JobPhases`,
            callback: (res) => {
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    return res.data.instance.filter((item) => {
                        if (item.isAgency && userStore.companyData.isAgency) {
                            return item
                        }
                        if (!item.isAgency && !userStore.companyData.isAgency) {
                            return item
                        }
                    }).map(i => {
                        return {
                            label: t(i.translationKey),
                            value: i.id,
                            raw: i
                        }
                    })
                }
            }
        })
    })

    useEffect(() => {
        if (!userStore.userData) { return }
        handleAllPermissions(userStore.userData.permissionsData, [
            ["PM_VIEW_DASHBOARD", setPM_DashboardView],
        ])
    }, [userStore.userData]);

    useEffect(() => {
        if (!userStore.flagData || !userStore.flagData.toggleDashboardAdvanced) { return setIsPremiumPlan(false) }
        setIsPremiumPlan(userStore.flagData.toggleDashboardAdvanced)
    }, [userStore.flagData])

    return (
        <GuestRedirectLayout>

            <DashboardLayout data={dbGeneralData} type={"Recruiter"}>
                <>

                    <div className="flex flex-row align-items-center flex-nowrap mb-4" style={{ gap: 5 }}>
                        {
                            recruitersData &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={recruitersDataIsLoading}
                                options={recruitersData ? [{ label: t("business_dashboard_filter_select_all_recruiters"), value: null }, ...recruitersData] : []}
                            />
                        }
                        {
                            phasesData &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={phasesDataIsLoading}
                                options={phasesData ? [{ label: t("business_dashboard_filter_select_all_phases"), value: null }, ...phasesData] : []}
                            />
                        }

                        <Select
                            placeholder="Select Date"
                            className="w-100"
                            defaultValue={null}
                            options={[
                                {
                                    "label": t("business_dashboard_filter_select_all_dates"),
                                    "value": null
                                },
                                {
                                    "label": "Oct '23",
                                    "value": "Oct '23"
                                },
                                {
                                    "label": "Nov '23",
                                    "value": "Nov '23"
                                },
                                {
                                    "label": "Dec '23",
                                    "value": "Dec '23"
                                },
                                {
                                    "label": "Jan '24",
                                    "value": "Jan '24"
                                },
                                {
                                    "label": "Feb '24",
                                    "value": "Feb '24"
                                },
                                {
                                    "label": "Mar '24",
                                    "value": "Mar '24"
                                },
                                {
                                    "label": "Apr '24",
                                    "value": "Apr '24"
                                },
                                {
                                    "label": "May '24",
                                    "value": "May '24"
                                },
                                {
                                    "label": "Jun '24",
                                    "value": "Jun '24"
                                },
                                {
                                    "label": "Jul '24",
                                    "value": "Jul '24"
                                },
                                {
                                    "label": "Sep '24",
                                    "value": "Sep '24"
                                },
                                {
                                    "label": "Oct '24",
                                    "value": "Oct '24"
                                },
                                {
                                    "label": "Nov '24",
                                    "value": "Nov '24"
                                }
                            ]}
                        />
                    </div>
                    <DemoPanelDedicatedApplicants />
                    <section>
                        <>
                            <div className="row">
                                <div className="col gx-2">
                                    <DemoCandidateBrandExperiencePanel
                                        filterPhaseId={null}
                                        filterUserId={null}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col gx-2">
                                    <DemoMessagesPanel />
                                </div>
                            </div>
                        </>
                    </section>
                </>
            </DashboardLayout>

        </GuestRedirectLayout>
    );
}
import { AutoComplete, Button, Empty, Form, Select, Table } from "antd";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { t } from "i18next";
import { useMemo, useState } from "react";
import NoData from "../no-data/nodata";
import Loading from "components/animations/loading";
import { useUserStore } from "components/utils/stores/userStore";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useQuery } from "@tanstack/react-query";
import { getDateInFormat } from "components/utils/methods/MyMethods";
import moment from "moment";

export default function MotivatedCandidatesBlock(props) {
    const userStore = useUserStore();
    const [filterDate, setFilterDate] = useState(null);
    const [filterDates, setFilterDates] = useState(null);
    const [filterJobNames, setFilterJobNames] = useState(undefined);
    const [filterJob, setFilterJob] = useState(undefined);
    const fixedDates = ["3days", "week", "month"];
    const { data: dbScoreEvolution, isLoading: dbScoreEvolutionIsLoading, isError: dbScoreEvolutionIsError, refetch: dbScoreEvolutionRefetch, isRefetching: dbScoreEvolutionIsRefetching } = useGetDbScoreEvolution({ filterJob, filterDate, category: "General", key: "getScore-BC_General", enabled: true, setFilterDates: !filterDate ? setFilterDates : null });

    const hasFiltersCondition = useMemo(() => filterJob === undefined || filterDate === undefined ? false : true, [filterJob, filterDate]);

    const { data, refetch, isError, isLoading, isRefetching } = useQuery({
        enabled: true,
        queryKey: ["getDedicatedApplicants"],
        queryFn: () => {
            const isFixedDate = fixedDates.includes(filterDate);
            const handleDate = (date) => {
                switch (date) {
                    case "3days":
                        return moment().subtract(3, "days");
                    case "week":
                        return moment().subtract(7, "days");
                    case "month":
                        return moment().subtract(1, "month");
                    default:
                        return date;
                }
            }

            const date = isFixedDate ? handleDate(filterDate) : filterDate === "all" ? null : filterDate;

            return sendAxiosPostRequest({
                route: "v5/select/business/dedicateds",
                body: {
                    BusinessId: userStore.companyData.id,
                    FilterUserId: null,
                    FilterPhaseId: null,
                    IsRelation: false,
                    FilterDate: date,
                    IsDateFixed: isFixedDate,
                },
                callback: (res) => {
                    if (res && res.data && res.data.instance && res.data.status === 200 && res.data.instance.length > 0) {
                        const newItems = [];
                        res.data.instance.forEach(element => {
                            if (element.dedicateds && element.dedicateds.length > 0) {
                                element.dedicateds.forEach(item => {
                                    newItems.push({
                                        jobName: element.jobName,
                                        date: item.createdOn ? getDateInFormat(element.createdOn, "MMM \'YY") : '',
                                        percentage: `${item.generalScore || 0}%`,
                                        email: item.email,
                                        name: item.firstName ? `${item.firstName || ''} ${item.lastName || ''}` : null,
                                    });
                                });
                            }
                        });
                        if (setFilterJobNames && !filterJobNames) {
                            const names = res.data.instance.map(item => {
                                return {
                                    label: item.jobName,
                                    value: item.jobName,
                                }
                            });
                            setFilterJobNames(names);
                        }
                        if (setFilterDates && !filterDates) {
                            const dates = res.data.instance.reduce((acc, item) => {
                                const date = item.createdOn ? getDateInFormat(item.createdOn, "MMM 'YY") : '';
                                if (date && !acc.some(d => d.value === date)) {
                                    acc.push({ label: date, value: date });
                                }
                                return acc;
                            }, []);
                            setFilterDates(dates);
                        }
                        return newItems
                    }
                    else {
                        return [];
                    }
                }
            });
        }
    });


    const columns = [
        // {
        //     title: "Date",
        //     dataIndex: 'date',
        //     key: 'date',
        // },
        {
            title: "Name",
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: "Email",
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: "Percentage",
            dataIndex: 'percentage',
            key: 'percentage',
        },
    ];

    return <>
        <h1 className='title'>{t("business_toolbox_page_motivated_candidates_title", { amount: hasFiltersCondition && data && data.length > 0 ? data.length : "" })}</h1>
        <p className="subtitle">{t("business_toolbox_page_motivated_candidates_subtitle")}</p>
        {
            dbScoreEvolutionIsError ? <NoData error /> :
                dbScoreEvolutionIsLoading ? <Loading center /> :
                    <>
                        <Form
                            onFinish={(values) => {
                                setFilterDate(values.date);
                                setFilterJob(values.job);
                                refetch();
                            }}
                            name="motivated-applicants" className="d-flex flex-row align-items-center mb-4" style={{ gap: "10px" }}>
                            <Form.Item
                                name="job"
                                className="mb-0 disable-explain"
                                style={{ width: "300px" }}
                                rules={[{ required: true, message: false }]}
                            >
                                {/* <Select
                                    placeholder={t("business_toolbox_page_motivated_candidates_filter_job")}
                                    options={filterJobNames ? [{ label: t("business_dashboard_filter_select_all_jobs"), value: "all" }, ...filterJobNames] : []}
                                /> */}

                                <AutoComplete
                                    allowClear
                                    placeholder={t("business_toolbox_page_motivated_candidates_filter_job")}
                                    filterOption={(inputValue, option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1}
                                    options={filterJobNames ? [{ label: t("business_dashboard_filter_select_all_jobs"), value: t("business_dashboard_filter_select_all_jobs") }, ...filterJobNames] : []}
                                />
                            </Form.Item>
                            <Form.Item
                                name="date"
                                className="mb-0 disable-explain"
                                style={{ width: "300px" }}
                                rules={[{ required: true, message: false }]}
                            >
                                <Select
                                    placeholder={t("business_toolbox_page_motivated_candidates_filter_date")}
                                    options={filterDates ? [
                                        { label: t("business_dashboard_filter_select_all_dates"), value: "all" },
                                        { label: t("business_dashboard_filter_select_3days"), value: "3days" },
                                        { label: t("business_dashboard_filter_select_week"), value: "week" },
                                        { label: t("business_dashboard_filter_select_month"), value: "month" },
                                        ...filterDates] : []}
                                />
                            </Form.Item>
                            <Button className="btn btn-primary" style={{ minHeight: 48 }} htmlType="submit" >{t("business_toolbox_page_filter_submit")}</Button>
                        </Form>
                        <Table
                            size="middle"
                            locale={{
                                emptyText: (
                                    hasFiltersCondition ? <NoData className="color-grey my-4" /> :
                                        <Empty className="my-2 mb-4" description={t("business_toolbox_page_motivated_candidates_table_add_filter")} />
                                ),
                            }}
                            className="m-0"
                            pagination={false}
                            isLoading={isLoading || isRefetching}
                            columns={columns}
                            dataSource={hasFiltersCondition ? data : []}
                        />
                    </>
        }
    </>

}

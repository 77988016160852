import { Empty, Modal, Select, Table } from "antd";
import AccordionContent from "components/layouts/accordions/accordionContent";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel from "components/layouts/dashboard/panels/messages-panel";
import ScoreEvolutionPanel from "components/layouts/dashboard/panels/score-evolution-panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { useUserStore } from "components/utils/stores/userStore";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { adminRoleId } from "components/utils/methods/AppMethods";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useQuery } from "@tanstack/react-query";
import Rating from "components/modules/rating/rating";
import { Link } from "react-router-dom";
import { DemoPanelDedicatedApplicants, PanelDedicatedApplicants } from "components/layouts/dashboard/basic/db-premium";

export default function DedicatedDashboardPage(props) {
    // # STORES
    const userStore = useUserStore();
    const { t } = useTranslation()
    const [filterUserId, setFilterUserId] = useState(null);
    const [filterPhaseId, setFilterPhaseId] = useState(null);
    const [filterDate, setFilterDate] = useState(null);
    const [filterDates, setFilterDates] = useState(null);
    const [filterJobNames, setFilterJobNames] = useState(null);
    const [filterJobName, setFilterJobName] = useState(null);
    const isAdmin = userStore.userData && userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;

    // # SERVER STATES
    // base data
    const { data: dbGeneralData, isLoading: dbGeneralDataIsLoading, isRefetching: dbGeneralDataIsRefetching, isError: dbGeneralDataIsError, refetch } = useGetDbGeneralData({ enabled: true }); // company data (number of ratings, rating, companyname, planname)
    const { data: recruitersData, isLoading: recruitersDataIsLoading, isSuccess: recruitersDataIsSuccess } = useQuery({
        cacheTime: 0,
        queryKey: ["dbGetAllRecruiters"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/recruiters`,
            body: {
                BusinessId: userStore.companyData.id
            },
            callback: (res) => {
                if (res && res.data && res.data.status === 290) { return [] }
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    var newArray = []
                    res.data.instance.map((item) => {
                        if (isAdmin) {
                            newArray.push({
                                label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                value: item.id,
                                raw: item
                            })
                        } else {
                            if (item.id === userStore.userData.id) {
                                setFilterUserId(item.id);
                                newArray.push({
                                    label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                    value: item.id,
                                    raw: item
                                })
                            }
                        }
                    })

                    return newArray;
                }
                else {
                    return []
                }
            }
        })
    })


    const [isSticky, setIsSticky] = useState(false);
    const targetRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const targetElement = targetRef.current;
            if (targetElement) {
                const { top } = targetElement.getBoundingClientRect();
                setIsSticky(top <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    // const { data: phasesData, isLoading: phasesDataIsLoading } = useQuery({
    //     queryKey: ["dbGetAllPhases"],
    //     queryFn: () => sendAxiosGetRequest({
    //         route: `v2/generic/catalogues/JobPhases`,
    //         callback: (res) => {

    //             if (res && res.data && res.data.instance && res.data.instance.length > 0) {
    //                 return res.data.instance.filter((item) => {
    //                     if (item.isAgency && userStore.companyData.isAgency) {
    //                         return item
    //                     }
    //                     if (!item.isAgency && !userStore.companyData.isAgency) {
    //                         return item
    //                     }
    //                 }).map(i => {
    //                     return {
    //                         label: t(i.translationKey),
    //                         value: i.id,
    //                         raw: i
    //                     }
    //                 })
    //             }
    //         }
    //     })
    // })


    useEffect(() => {
        if (recruitersDataIsSuccess) {
            refetch();
        }
    }, [filterUserId, filterPhaseId, filterDate, recruitersDataIsSuccess]);

    return <>
        <DashboardLayout data={dbGeneralData} type={t("business_dashboard_type_dedicated")}>
            <>
                <div ref={targetRef} style={isSticky ? { height: 50 } : { height: 0 }} ></div>
                <div className={`mb-4 ${isSticky ? 'fixed py-3 bg-lime ' : ''}`} style={isSticky ? { top: 0, left: 0, zIndex: 100, width: "100vw", maxWidth: "100vw !important", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" } : { gap: 5 }}>
                    <div className={`flex flex-row align-items-center flex-nowrap ${isSticky ? 'container' : ''}`} style={{ gap: 5 }}>
                        {
                            recruitersData &&
                            <Select
                                className="w-100"
                                value={filterUserId}
                                style={{ minWidth: 300 }}
                                loading={recruitersDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterUserId(null);
                                    else setFilterUserId(value);
                                }}
                                options={recruitersData ? [{ label: t("business_dashboard_filter_select_all_recruiters"), value: null }, ...recruitersData] : []}
                            />
                        }
                        {/* {
                            phasesData &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={phasesDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterPhaseId(null);
                                    else setFilterPhaseId(value);
                                }}
                                options={phasesData ? [{ label: t("business_dashboard_filter_select_all_phases"), value: null }, ...phasesData] : []}
                            />
                        } */}
                        {
                            filterDates && filterDates.length > 0 &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={recruitersDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterDate(null);
                                    else setFilterDate(value);
                                }}
                                options={filterDates ? [{ label: t("business_dashboard_filter_select_all_dates"), value: null }, ...filterDates] : []}
                            />
                        }
                        {
                            filterJobNames && filterJobNames.length > 0 &&
                            <Select
                                className="w-100"
                                defaultValue={null}
                                style={{ minWidth: 300 }}
                                loading={recruitersDataIsLoading}
                                onChange={(value) => {
                                    if (value === null) setFilterJobName(null);
                                    else setFilterJobName(value);
                                }}
                                options={filterJobNames ? [{ label: t("business_dashboard_filter_select_all_jobs"), value: null }, ...filterJobNames] : []}
                            />
                        }
                    </div>
                </div>
                <section className="row mb-10">
                    <PanelDedicatedApplicants
                        filterUserId={filterUserId}
                        filterPhaseId={filterPhaseId}
                        filterDate={filterDate}
                        setFilterDates={setFilterDates}
                        setJobNames={setFilterJobNames}
                        selectedJobName={filterJobName}
                    />
                </section>
            </>
        </DashboardLayout>
    </>
}

import { useQuery } from "@tanstack/react-query";
import { Segmented } from "antd";
import Panel from "components/modules/panel/panel";
import { sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import NoData from "../no-data/nodata";
import BarChart from "../charts/barchart";

export default function SmartSortingBlock(props) {
    const userStore = useUserStore();

    const { data, isLoading, isError, isFetching } = useQuery({
        queryKey: ["smart-sorting"],
        queryFn: () => sendAxiosPostRequest({
            route: `v6/select/insights/sorting`,
            body: {
                UserId: userStore.userData.id,
                BusinessId: userStore.companyData.id
            },
        })
    });

    const segmentOptions = [
        {
            label: t("business_toolbox_page_smart_sorting_segment_label_motivated_applicants"),
            value: "motivated_applicants",
            backendKey: "sortingDedicated"
        },
        {
            label: t("business_toolbox_page_smart_sorting_segment_label_job_attractiveness"),
            value: "job_attractiveness",
            backendKey: "sortingJobAttractiveness"
        },
        {
            label: t("business_toolbox_page_smart_sorting_segment_label_employer_brand"),
            value: "employer_brand",
            backendKey: "sortingBrand"
        },
        {
            label: t("business_toolbox_page_smart_sorting_segment_label_candidate_service"),
            value: "candidate_service",
            backendKey: "sortingCandidateService"
        },
    ]


    const [segment, setSegment] = useState(segmentOptions[0].value);
    const chartData = useMemo(() => data && segmentOptions.find(x => x.value === segment) && data[segmentOptions.find(x => x.value === segment).backendKey] && data[segmentOptions.find(x => x.value === segment).backendKey].map(item => {
        return {
            value: item.value,
            label: item.label,
            valueColor: 'hsl(206, 96.70%, 48.00%)'
        }
    }), [data, segment]);

    return <>
        <section>
            <h1 className="title mb-4">{t("business_toolbox_smart_sorting")}</h1>
            <Panel
                isError={isError} isLoading={isFetching}>
                <div className='flex justify-content-center w-100 my-4'>
                    <Segmented
                        className='border-white-700 border segmented-special'
                        size='large'
                        value={segment}
                        options={segmentOptions}
                        onChange={(value) => setSegment(value.toString())}
                    />
                    {
                        segment && segmentOptions.find(x => x.value === segment) && segmentOptions.find(x => x.value === segment).backendKey && data && data[segmentOptions.find(x => x.value === segment).backendKey] ? <>
                            <BarChart
                                maxWidth={1370}
                                keys={["value"]}
                                indexBy={"label"}
                                labelTextColor="#ffffff"
                                xAxis={null}
                                yAxis={null}
                                data={chartData.sort((a, b) => b.value - a.value)}
                                hideLegends
                            />
                        </> : <NoData className="mt-5 mb-2" />
                    }
                </div>
            </Panel>
        </section>
    </>;
}

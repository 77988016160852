import { useQuery } from "@tanstack/react-query";
import { Select } from "antd";
import Loading from "components/animations/loading";
import DashboardLayout from "components/layouts/dashboard/dashboard-layout";
import CandidateBrandExperiencePanel from "components/layouts/dashboard/panels/candidate-brand-experience-panel";
import MessagesPanel from "components/layouts/dashboard/panels/messages-panel";
import DemoBarChart from "components/modules/demo/demo-bar-chart";
import Panel from "components/modules/panel/panel";
import { useGetDbGeneralData } from "components/requests/dashboard/getDbGeneralData";
import useGetDbMessages from "components/requests/dashboard/getDbMessages";
import { useGetDbScoreEvolution } from "components/requests/dashboard/getDbScoreEvolution";
import { adminRoleId, dateFormat } from "components/utils/methods/AppMethods";
import { getDateInFormat } from "components/utils/methods/MyMethods";
import { sendAxiosGetRequest, sendAxiosPostRequest } from "components/utils/methods/myRequestHandlers";
import { useUserStore } from "components/utils/stores/userStore";
import { t } from "i18next";
import moment from "moment";
import { useGetJobs } from "pages/jobs/jobs";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export default function AgencySmartInsightsBlock(props) {
    // # STORES
    const userStore = useUserStore();
    const { t } = useTranslation()
    const [filterUserId, setFilterUserId] = useState(null);
    const [filterPhaseId, setFilterPhaseId] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [filterDate, setFilterDate] = useState(null);
    const [filterDates, setFilterDates] = useState(null);
    const [filterJob, setFilterJob] = useState(null);
    const [filterJobs, setFilterJobs] = useState(null);
    const isAdmin = userStore.userData && userStore.userData.roleData && userStore.userData.roleData.id.toLowerCase() === adminRoleId.toLowerCase() ? true : false;

    // # SERVER STATES
    // base data
    const { data: dbScoreEvolution, isLoading: dbScoreEvolutionIsLoading, isError: dbScoreEvolutionIsError, refetch: dbScoreEvolutionRefetch, isRefetching: dbScoreEvolutionIsRefetching } = useGetDbScoreEvolution({
        filterPhaseId, filterDate, filterUserId, category: "General", key: "getScore-BC_General-agency", enabled: true, setFilterDates: !filterDate ? setFilterDates : null, extraCallback: (result) => {
            if (result && result.data.status === 200 && result.data.instance && result.data.instance.length > 0) {
                const res = result.data.instance;
                const item = res[0];
                const newStartDate = moment({ year: item.yearIndex, month: item.monthIndex }).format('YYYY-MM-DD');

                setStartDate(newStartDate);
            }
            // else {
            //     setStartDate(null);
            // }
        }
    });

    const { refetch: jobsRefetch, isLoading: jobsIsLoading, isRefetching: jobsIsRefetching, isError } = useGetJobs({ setData: setFilterJobs });

    const { data: dataNps, refetch: refetchNps, isLoading: isLoadingNps, isRefetching: isRefetchingNps } = useGetScoreExperienceTimeline({ category: "NPS", filterUserId, filterJob, filterPhaseId, dateStart: startDate, dateEnd: endDate }); // attr & cariere kansen
    const { data: dataJobMatch, refetch: refetchJobMatch, isLoading: isLoadingJobMatch, isRefetching: isRefetchingJobMatch } = useGetScoreExperienceTimeline({ category: "JobMatch", filterUserId, filterJob, filterPhaseId, dateStart: startDate, dateEnd: endDate }); // attr job & fit job
    const { data: dataHelp, refetch: refetchHelp, isLoading: isLoadingHelp, isRefetching: isRefetchingHelp } = useGetScoreExperienceTimeline({ category: "Help", filterUserId, filterJob, filterPhaseId, dateStart: startDate, dateEnd: endDate }); // info over job en org & info over soll gesprek
    const { data: recruitersData, isLoading: recruitersDataIsLoading, isSuccess: recruitersDataIsSuccess } = useQuery({
        cacheTime: 0,
        queryKey: ["dbGetAllRecruiters"],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/recruiters`,
            body: {
                BusinessId: userStore.companyData.id
            },
            callback: (res) => {
                if (res && res.data && res.data.status === 290) { return [] }
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    var newArray = []
                    res.data.instance.map((item) => {
                        if (isAdmin) {
                            newArray.push({
                                label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                value: item.id,
                                raw: item
                            })
                        } else {
                            if (item.id === userStore.userData.id) {
                                setFilterUserId(item.id);
                                newArray.push({
                                    label: `${!item.firstName ? item.email : `${item.firstName}${item.lastName ? ` ${item.lastName}` : ""}`}`,
                                    value: item.id,
                                    raw: item
                                })
                            }
                        }
                    })

                    return newArray;
                }
                else {
                    return []
                }
            }
        })
    });

    const { data: phasesData, isLoading: phasesDataIsLoading } = useQuery({
        queryKey: ["dbGetAllPhases"],
        queryFn: () => sendAxiosGetRequest({
            route: `v2/generic/catalogues/JobPhases`,
            callback: (res) => {
                if (res && res.data && res.data.instance && res.data.instance.length > 0) {
                    return res.data.instance.filter((item) => {
                        if (item.isAgency && userStore.companyData.isAgency) {
                            return item
                        }
                        if (!item.isAgency && !userStore.companyData.isAgency) {
                            return item
                        }
                    }).map(i => {
                        return {
                            label: t(i.translationKey),
                            value: i.id,
                            raw: i
                        }
                    })
                }
            }
        })
    })

    const [isSticky, setIsSticky] = useState(false);
    const targetRef = useRef(null);

    useEffect(() => {
        if (filterDate) {
            const newDate = getDateInFormat(filterDate, 'YYYY-MM-DD')
            setStartDate(newDate);
            setEndDate(newDate);
        } else if (filterDates) {
            const newDate = moment().subtract(1, 'years').format('YYYY-MM-DD')
            setEndDate(null);
            setStartDate(newDate);
        }
    }, [filterDate])

    useEffect(() => {
        if (startDate) {
            refetchNps();
            refetchJobMatch();
            refetchHelp();
        }
    }, [dbScoreEvolution, startDate, endDate, filterJob, filterPhaseId, filterUserId])

    useEffect(() => {
        const handleScroll = () => {
            const targetElement = targetRef.current;
            if (targetElement) {
                const { top } = targetElement.getBoundingClientRect();
                setIsSticky(top <= 0);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return <>
        <div className="row relative">
            <div ref={targetRef} style={isSticky ? { height: 50 } : { height: 0 }} ></div>
            <div className={`mb-4 ${isSticky ? 'fixed py-3 bg-lime ' : ''}`} style={isSticky ? { top: 0, left: 0, zIndex: 100, width: "100vw", maxWidth: "100vw !important", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" } : { gap: 5 }}>
                <div className={`flex flex-row-reverse align-items-center flex-nowrap ${isSticky ? 'container' : ''}`} style={{ gap: 5 }}>
                    {
                        recruitersData && isAdmin &&
                        <Select
                            className="w-100"
                            value={filterUserId}
                            style={{ minWidth: 300 }}
                            loading={recruitersDataIsLoading}
                            onChange={(value) => {
                                if (value === null) setFilterUserId(null);
                                else setFilterUserId(value);
                            }}
                            options={recruitersData ? [{ label: t("business_dashboard_filter_select_all_recruiters"), value: null }, ...recruitersData] : []}
                        />
                    }
                    {
                        phasesData &&
                        <Select
                            className="w-100"
                            defaultValue={null}
                            style={{ minWidth: 300 }}
                            loading={phasesDataIsLoading}
                            onChange={(value) => {
                                if (value === null) setFilterPhaseId(null);
                                else setFilterPhaseId(value);
                            }}
                            options={phasesData ? [{ label: t("business_dashboard_filter_select_all_phases"), value: null }, ...phasesData] : []}
                        />
                    }
                    {
                        filterDates && filterDates.length > 0 &&
                        <Select
                            className="w-100"
                            defaultValue={null}
                            style={{ minWidth: 300 }}
                            loading={recruitersDataIsLoading}
                            onChange={(value) => {
                                if (value === null) setFilterDate(null);
                                else {
                                    setFilterDate(value);
                                }
                            }}
                            options={filterDates ? [{ label: t("business_dashboard_filter_select_all_dates"), value: null }, ...filterDates] : []}
                        />
                    }
                    {
                        filterJobs && filterJobs.length > 0 &&
                        <Select
                            className="w-100"
                            defaultValue={null}
                            style={{ minWidth: 300 }}
                            loading={recruitersDataIsLoading}
                            onChange={(value) => {
                                if (value === null) setFilterJob(null);
                                else setFilterJob(value);
                            }}
                            options={filterJobs ? [{ label: t("business_dashboard_filter_select_all_jobs"), value: null }, ...filterJobs.map((item) => ({ label: item.jobName, value: item.jobId }))] : []}
                        />
                    }
                </div>
            </div>
        </div>
        {/* ### APPLICANT EXPERIENCE ###  */}
        {
            isLoadingNps ||
                isLoadingJobMatch ||
                isLoadingHelp ||
                recruitersDataIsLoading ||
                isRefetchingNps ||
                isRefetchingJobMatch ||
                isRefetchingHelp ? <Loading center /> :

                <section>
                    <h1 className="title mb-4">{t("business_toolbox_smart_analytics_insights")}</h1>
                    <>
                        <Panel
                            bodyStyle={{
                                height: 400,
                            }}
                        >
                            <DemoBarChart
                                data={{
                                    labels: handleChartLabelsPerExperience([
                                        dataNps,
                                        dataJobMatch,
                                        dataHelp,
                                    ]),
                                    datasets: [
                                        ...handleChartDatasetPerExperience({
                                            data: dataNps,
                                            label: t("business_toolbox_page_nps")
                                        }),
                                        ...handleChartDatasetPerExperience({
                                            data: dataJobMatch,
                                            label: t("business_toolbox_page_jobmatch"),
                                        }),
                                        ...handleChartDatasetPerExperience({
                                            data: dataHelp,
                                            label: t("business_toolbox_page_agency_help"),
                                        })
                                    ],
                                }}
                                options={{
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: {
                                            display: false,
                                        },
                                        customLabelPlugin: {
                                            enabled: true,
                                        },
                                    },
                                    scales: {
                                        x: {
                                            stacked: true,
                                        },
                                        y: {
                                            stacked: true,
                                        },
                                    },
                                }}
                            />
                        </Panel>
                    </>
                </section>
        }
    </>
}

const handleChartLabelsPerExperience = (arr) => {
    const labels = [];
    arr.map((item) => {
        item.map((x) => {
            let formattedDate = getDateInFormat(x.timestamp, "MMM \'YY");
            if (!labels.includes(formattedDate)) {
                labels.push(formattedDate)
            }
        })
    });
    return labels;
}

export const useGetScoreExperienceTimeline = (props) => {
    const userStore = useUserStore();

    return useQuery({
        queryKey: [`getScoreExperienceTimeline-${props.category}`],
        queryFn: () => sendAxiosPostRequest({
            route: `v5/select/business/scores/experiences/timeline`,
            body: {
                BusinessId: userStore.companyData.id,
                Category: props.category,
                FilterUserId: props.filterUserId || null, // optional
                FilterPhaseId: props.filterPhaseId || null, // optional
                FilterJobId: props.filterJob || null, // optional
                FilterClusterId: props.filterClusterId || null, // optional
                DateStart: props.dateStart,
                DateEnd: props.dateEnd || null, // optional
                IsRelation: props.isRelation || false,
            },
            callback: (res) => {
                if (res && res.data.instance && parseInt(res.data.status) === 290) {
                    return null;
                } else {
                    return res.data.instance.sort((a, b) => a.timestamp - b.timestamp)
                }
            }
        }),
        enabled: false
    })
}


const handleChartDatasetPerExperience = (props) => {
    const mapping = (keyDetector, experiences) => {
        var val = null;
        if (!experiences) return val
        else {
            var fItem = experiences.find((x) => x.translationKey.includes(keyDetector))
            if (!fItem) return val
            else {
                return fItem.amount;
            }
        }
    }
    const negative = props.data.map((item) => {
        return mapping("_negative", item.experiences);
    });
    const positive = props.data.map((item) => {
        return mapping("_positive", item.experiences);
    });;
    const moderate = props.data.map((item) => {
        return mapping("_moderate", item.experiences);
    });;



    const bgRed = 'rgba(255, 0, 0, 1)';
    const bgYellow = 'rgba(255, 206, 86, 1)';
    const bgGreen = 'rgba(102, 187, 106, 1)';

    return [
        {
            label: `${props.label} - ${t("business_main_negative")}`,
            data: negative,
            backgroundColor: [bgRed],
            borderWidth: 1,
            stack: `${props.label.replaceAll(" ", "").toLowerCase()}`,
        },
        {
            label: `${props.label} - ${t("business_main_moderate")}`,
            data: moderate,
            backgroundColor: [bgYellow],
            borderWidth: 1,
            stack: `${props.label.replaceAll(" ", "").toLowerCase()}`,
        },
        {
            label: `${props.label} - ${t("business_main_positive")}`,
            data: positive,
            backgroundColor: [bgGreen],
            borderWidth: 1,
            stack: `${props.label.replaceAll(" ", "").toLowerCase()}`,
        },
    ]
}